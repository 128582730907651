import React, { Component } from 'react'
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import axios from 'axios';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import secureLocalStorage from 'react-secure-storage';
import '../css/onlinetable.css';
import { withTranslation } from 'react-i18next';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { createPopper } from '@popperjs/core';
import Select from "react-select";
import { Link } from 'react-router-dom';
import { forEach } from 'jszip';
import moment from 'moment/moment';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
class OnlineTable extends Component {
    constructor(props){
        super(props);
        this.state = { 
            names: props.names, names1: props.names1, allowed: [],
            user:"",dst:"",prefix:"", options:[], transferto:"", brands: ["all"], brandsRender:[]
    }
    }
    componentDidMount(){
        setInterval(()=>{
            this.setState({ names: this.props.names, names1: secureLocalStorage.getItem('names1')});
            },1000)
    axios.post('https://www.clearcall.tel:8078/agents/onlineaccess', {
            ui_id:JSON.parse(secureLocalStorage.getItem('user')).user_info_id,
            company_id:JSON.parse(secureLocalStorage.getItem('user')).company_id
       })
       .then((res)=>{
        this.state.allowed = res.data;
        console.log(res.data);

        if(this.state.names.length>0)
        for (let index = 0; index < JSON.parse(this.state.names).length; index++) {
            const element = JSON.parse(this.state.names)[index];
            if(!this.state.brands.includes(res.data[res.data.findIndex(val=>val.Login===element["sip_user"])]?.Title))
            this.state.brands.push(res.data[res.data.findIndex(val=>val.Login===element["sip_user"])]?.Title);
        }
        if(this.state.names1.length>0)
        for (let index = 0; index < JSON.parse(this.state.names1).length; index++) {
            const element = JSON.parse(this.state.names1)[index];
            if(!this.state.brands.includes(res.data[res.data.findIndex(val=>val.Login===element["sip_user"])]?.Title))
            this.state.brands.push(res.data[res.data.findIndex(val=>val.Login===element["sip_user"])]?.Title);
       }
       console.log(this.state.brands);
       for (let index = 0; index < this.state.brandsRender.length; index++) {
        const element = this.state.brandsRender[index];
        document.getElementById(element).classList.toggle('btn-primary');
        console.log(document.getElementById(element).classList)
       }

       })
       .catch((err)=>console.log(err))
    $('#tablecalls tbody').on('click', 'tr', function() {
        $('#tablecalls tbody > tr').removeClass('row_selected');
        $(this).addClass('row_selected');
    });
    }
    axiosTest(sip) {
        return axios.post("https://www.clearcall.tel:8078/agents/getTeam", {sip}).then(response => response.data)
    }
    team ='';
    getTeam(sip){
        this.axiosTest(sip).then(data=>{
            this.team = data[0].title;
        })
        console.log(this.team);
    }
    handleListen(uuid,server){
        let user = JSON.parse(secureLocalStorage.getItem('user')).login;
        axios.post("https://www.clearcall.tel:8078/listen",{
            user,
            domain:'www.pbxdiamondcall.com',
            uuid,
            server
        })
        .then(res=>{
            console.log(`Command send: ${res.data}`,`uuid send: ${uuid}`);
        })
        .catch(err=>console.log(err));
        this.refresh();
    }
    handleSufl(uuid,server){
        let user = JSON.parse(secureLocalStorage.getItem('user')).login;
        axios.post("https://www.clearcall.tel:8078/sufl",{
            user,
            domain:'10.3.1.1',
            uuid,
            server
        })
        .then(res=>{
            console.log(res);
        })
        .catch(err=>console.log(err));
        this.refresh();
    }
    handleSend(uuid,server){
        axios.post('https://www.clearcall.tel:8078/sendcommands', {
            uuid,
            server
        })
        .then((res)=>console.log(res))
        .catch((err)=>console.log(err))
        this.refresh();
    };
    handleEnd(uuid,server){
        axios.post('https://www.clearcall.tel:8078/end', {
            uuid,
            server
        })
        .then((res)=>console.log(res))
        .catch((err)=>console.log(err))
        this.refresh();
    };
    handleTransfer=(uuid,server,user,dest)=>{
        console.log(uuid,server,user,dest);
        axios.post('https://www.clearcall.tel:8078/agents/getTeammates', {
            user
        })
        .then((res)=>{
            console.log(res.data)
            var arr = [];
            res.data.forEach((element, idx) => {
                arr.push({value:element.Id,label:`${element.Login}`});
            });
            this.setState(prev=>{
                return {
                    ...prev,
                    name:user,
                    dst:dest.slice(3),
                    prefix:dest.slice(0,3),
                    options:arr
                }    
            })
        })
        .catch((err)=>console.log(err))
        
        
        console.log(this.state);

    }
    refresh=()=>{
        this.setState({ names: secureLocalStorage.getItem('names'), names1: secureLocalStorage.getItem('names1')})
        console.log(secureLocalStorage.getItem('names'));
        // $('#table').DataTable().clear().draw();
        // $('#table').DataTable().rows.add(this.names);
        // $('#table').DataTable().rows.add(this.names1);
        // $('#table').DataTable().columns.adjust().draw();
    };
    handleChoose=(e)=>{
        this.setState(prev=>{
            return {
                ...prev,
                transferto:e.value
            }
        })
    }
    handleClose(){
        this.setState(prev=>{
            return {
                ...prev,
                transferto:""
            }
        })
    }
    
    showTable = () => {
        const { t } = this.props;
            try {
                return JSON.parse(this.state.names).sort((a,b)=>a["duration"]>b["duration"]?-1:1).map((msg,idx)=>(
                    msg["dst"].match(/^(\d{12,})$/)!=null ?
                    JSON.parse(secureLocalStorage.getItem('user')).role_id !== 1 ?
                    this.state.allowed.some(us=>us.Login===msg["sip_user"])?
                    msg["dst"].substring(0,3)!=="810"?
                    <tr key={msg['uuid']} >
                         {/* <td>{msg["server"]}</td> */}
                         <td>{msg["time"]}</td>
                        
                        <td>{msg["sip_user"]}</td>
                        {JSON.parse(secureLocalStorage.getItem('user')).role_id == 1 ?
                        <td>{this.state.allowed[this.state.allowed.findIndex(val=>val.Login===msg["sip_user"])].Title}</td>
                        :null
                        }
                        <td>{msg["team"]}</td>
                        <td>{msg["dst"].substring(0,2)}</td>
                        <td>{msg["dst"].substring(2)}</td>
                        {/* <td>
                            {msg["status"]=='Initiated'
                            ?
                            <button className="btn btn-label-warning" disabled={true} style={{width:100}}>
                                    {t("onlineRinging")}
                            </button>
                            :<button className="btn btn-label-success" disabled={true} style={{width:100}}>
                            {t("onlineInCall")}
                    </button>}
                        </td> */}
                    <td>{parseInt(msg["duration"])>3600?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(11, 19)
                                        :parseInt(msg["duration"]) < 60?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(17, 19) + ` ${t("onlineSeconds")}`
                                        : new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(14, 19)}</td>
                         <td>
                                <div>
                                    <Link  to={`callto:99${msg["sip_user"]}`}>
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:4,
                                        width:"8vw",
                                        height:"3vh",
fontSize:"1.4vh",
                                        margin:"0.3vh",
                                        padding:"0.5vh",
                                backgroundColor:'#25A0E2',
                                color:"#fff"
                                        }}
                                        >
                                            Listen
                                            <Tooltip id="tooltip" />
                                    </button></Link>
                                    <Link  to={`callto:98${msg["sip_user"]}`}>
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:4,
                                        width:"8vw",
                                        height:"3vh",
fontSize:"1.4vh",
                                        margin:"0.3vh",
                                        padding:"0.5vh",
                                backgroundColor:"#00BD9D",
                                color:"#fff"
                                        }}
                                        >
                                            Sufler
                                            <Tooltip id="tooltip" />
                                    </button>
                                    </Link>
                                    
                                {/* 
                                        
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:4,
                                        width:"8vw",
                                        height:"3vh",
fontSize:"1.4vh",
                                        margin:3,
                                        padding:5
                                        }}
                                        data-bs-toggle="modal" data-bs-target="#modalTop"
                                        >
                                            <i className="fa-solid fa-right-left fa-xl" onClick={()=>this.handleTransfer(msg["uuid"],msg["server"],msg["sip_user"],msg["dst"])} 
                                            id="transfer" data-tooltip-id="tooltip" data-tooltip-content={"Transfer call"}></i>
                                            <Tooltip id="tooltip" />
                                    </button> */}
                                </div>
                        </td>
                    </tr>
                    :null
                    :null
                    :
                    msg["dst"].substring(0,3)!=="810"?
                    !this.state.brandsRender.includes("all")?
                    this.state.brandsRender.includes(msg["company"])?
                    <tr key={msg['uuid']} >
                     {/* <td>{msg["server"]}</td> */}
                    <td>{`${new Date(msg["time"]).getDate()}-${(new Date(msg["time"]).getMonth() + 1).toString().padStart(2, '0')}-${new Date(msg["time"]).getFullYear()} ${new Date(msg["time"]).getHours().toString().padStart(2, '0')}:${new Date(msg["time"]).getMinutes().toString().padStart(2, '0')}:${new Date(msg["time"]).getSeconds().toString().padStart(2, '0')}`}</td>
                    <td>{msg["team"]}</td>
                    <td>{msg["sip_user"]}</td>
                        {
                            JSON.parse(secureLocalStorage.getItem('user')).role_id == 1 ?
                            <td>{msg["company"]}</td>
                            :null
                        }
                    <td>{msg["dst"].substring(0,2)}</td>
                    <td>{msg["dst"].substring(2)}</td>
                    {/* <td>
                    {
                        msg["status"]=='Initiated'
                            ?
                            <button className="btn btn-label-warning" disabled={true} style={{width:100}}>
                                    {t("onlineRinging")}
                            </button>
                            :<button className="btn btn-label-success" disabled={true} style={{width:100}}>
                            {t("onlineInCall")}
                            </button>
                    }
                    </td> */}
                    <td>{parseInt(msg["duration"])>3600?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(11, 19)
                                        :parseInt(msg["duration"]) < 60?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(17, 19) + ` ${t("onlineSeconds")}`
                                        : new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(14, 19)}</td>
                    
                    <td>
                                <div>
                                    <Link  to={`callto:99${msg["sip_user"]}`}>
                                        <button className='btn' style={{
                                        border:2,
                                        borderRadius:4,
                                        width:"8vw",
                                        height:"3vh",
fontSize:"1.4vh",
                                        margin:"0.3vh",
                                        padding:"0.5vh",
                                backgroundColor:'#25A0E2',
                                color:"#fff"
                                        }}
                                            >
                                                Listen
                                                <Tooltip id="tooltip" />
                                        </button></Link>
                                        <Link  to={`callto:98${msg["sip_user"]}`}>
                                        <button className='btn' style={{
                                        border:2,
                                        borderRadius:4,
                                        width:"8vw",
                                        height:"3vh",
fontSize:"1.4vh",
                                        margin:"0.3vh",
                                        padding:"0.5vh",
                                        backgroundColor:"#00BD9D",
                                        color:"#fff"
                                        }}
                                            >
                                                Sufler
                                                <Tooltip id="tooltip" />
                                        </button>
                                        </Link>
                                    </div>
                            </td>
                </tr>
                :null
                :<tr key={msg['uuid']} style={{fontWeight:"bold"}}>
                 {/* <td>{msg["server"]}</td> */}
                <td>{`${new Date(msg["time"]).getDate()}-${(new Date(msg["time"]).getMonth() + 1).toString().padStart(2, '0')}-${new Date(msg["time"]).getFullYear()} ${new Date(msg["time"]).getHours().toString().padStart(2, '0')}:${new Date(msg["time"]).getMinutes().toString().padStart(2, '0')}:${new Date(msg["time"]).getSeconds().toString().padStart(2, '0')}`}</td>
                <td>{msg["team"]}</td>
                <td>{msg["sip_user"]}</td>
                {JSON.parse(secureLocalStorage.getItem('user')).role_id == 1 ?
                        <td>{this.state.allowed[this.state.allowed.findIndex(val=>val.Login===msg["sip_user"])].Title}</td>
                        :null
                        }
                <td>{msg["dst"].substring(0,2)}</td>
                <td>{msg["dst"].substring(2)}</td>
                <td>{parseInt(msg["duration"])>3600?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(11, 19)
                                    :parseInt(msg["duration"]) < 60?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(17, 19) + ` ${t("onlineSeconds")}`
                                    : new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(14, 19)}</td>
                {/* <td>
                {
                    msg["status"]=='Initiated'
                        ?
                        <button className="btn btn-label-warning" disabled={true} style={{width:100}}>
                                {t("onlineRinging")}
                        </button>
                        :<button className="btn btn-label-success" disabled={true} style={{width:100}}>
                        {t("onlineInCall")}
                        </button>
                }
                </td> */}
                
                <td>
                                <div>
                                    <Link  to={`callto:99${msg["sip_user"]}`}>
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:4,
                                        width:"8vw",
                                        height:"3vh",
fontSize:"1.4vh",
                                        margin:"0.3vh",
                                        padding:"0.5vh",
                                backgroundColor:'#25A0E2',
                                color:"#fff"
                                        }}
                                        >
                                            Listen
                                            <Tooltip id="tooltip" />
                                    </button></Link>
                                    <Link  to={`callto:98${msg["sip_user"]}`}>
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:4,
                                        width:"8vw",
                                        height:"3vh",
fontSize:"1.4vh",
                                        margin:"0.3vh",
                                        padding:"0.5vh",
                                backgroundColor:"#00BD9D",
                                color:"#fff"
                                        }}
                                        >
                                            Sufler
                                            <Tooltip id="tooltip" />
                                    </button>
                                    </Link>
                                    
                                {/* 
                                        
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:4,
                                        width:"8vw",
                                        height:"3vh",
fontSize:"1.4vh",
                                        margin:3,
                                        padding:5
                                        }}
                                        data-bs-toggle="modal" data-bs-target="#modalTop"
                                        >
                                            <i className="fa-solid fa-right-left fa-xl" onClick={()=>this.handleTransfer(msg["uuid"],msg["server"],msg["sip_user"],msg["dst"])} 
                                            id="transfer" data-tooltip-id="tooltip" data-tooltip-content={"Transfer call"}></i>
                                            <Tooltip id="tooltip" />
                                    </button> */}
                                </div>
                        </td>
                </tr>
                :null
                :null
                ))
            } 
            catch (e) {
                console.log(e.message);
            }
    };
    // showTable = () => {
    //     console.log(this.state);
    //     const { t } = this.props;
    //         try {
    //             return JSON.parse(this.state.names).sort((a,b)=>a["duration"]>b["duration"]?-1:1).map((msg,idx)=>(
    //                 msg["dst"].match(/^(\d{12,})$/)!=null ?
    //                 JSON.parse(secureLocalStorage.getItem('user')).role_id !== 1 ?
    //                 this.state.allowed.some(us=>us.Login===msg["sip_user"])?
    //                 msg["dst"].substring(0,3)!=="810"?
    //                 <tr key={msg['uuid']} style={{fontWeight:300,fontSize:"1.5vh",color:"black"}}>
    //                     {/* <td>{msg["server"]}</td> */}
    //                     <td>{msg["time"]}</td>
                        
    //                     <td>{msg["sip_user"]}</td>
    //                     {JSON.parse(secureLocalStorage.getItem('user')).role_id == 1 ?
    //                     <td>{this.state.allowed[this.state.allowed.findIndex(val=>val.Login===msg["sip_user"])]?.Title}</td>
    //                     :null
    //                     }
    //                     <td>{this.state.allowed[this.state.allowed.findIndex(val=>val.Login===msg["sip_user"])]?.team}</td>
    //                     <td>{msg["dst"].substring(0,2)}</td>
    //                     <td>{msg["dst"].substring(2)}</td>
    //                     {/* <td>
    //                         {msg["status"]=='EARLY'
    //                         ?
    //                         <button className="btn btn-label-warning" disabled={true} style={{width:100}}>
    //                                 {t("onlineRinging")}
    //                         </button>
    //                         :<button className="btn btn-label-success" disabled={true} style={{width:100}}>
    //                         {t("onlineInCall")}
    //                 </button>}
    //                     </td> */}
    //                     <td>{parseInt(msg["duration"])>3600?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(11, 19)
    //                                     :parseInt(msg["duration"]) < 60?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(17, 19) + ` ${t("onlineSeconds")}`
    //                                     : new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(14, 19)}</td>
    //                     <td>
    //                             <div>
    //                                 <Link  to={`callto:99${msg["sip_user"]}`}>
    //                                 <button className='btn' style={{
    //                                     border:2,
    //                                     borderRadius:4,
    //                                     width:"3vw",
    //                                     height:"3vh",
    //                                     margin:3,
    //                                     padding:5,
    //                             backgroundColor:'#25A0E2',
    //                             color:"#fff"
    //                                     }}
    //                                     >
    //                                         Listen
    //                                         <Tooltip id="tooltip" />
    //                                 </button></Link>
    //                                 <Link  to={`callto:98${msg["sip_user"]}`}>
    //                                 <button className='btn' style={{
    //                                     border:2,
    //                                     borderRadius:4,
    //                                     width:"3vw",
    //                                     height:"3vh",
    //                                     margin:3,
    //                                     padding:5,
    //                             backgroundColor:"#00BD9D",
    //                             color:"#fff"
    //                                     }}
    //                                     >
    //                                         Sufler
    //                                         <Tooltip id="tooltip" />
    //                                 </button>
    //                                 </Link>
                                    
    //                             {/* 
                                        
    //                                 <button className='btn' style={{
    //                                     border:2,
    //                                     borderRadius:4,
    //                                     width:"3vw",
    //                                     height:"3vh",
    //                                     margin:3,
    //                                     padding:5
    //                                     }}
    //                                     data-bs-toggle="modal" data-bs-target="#modalTop"
    //                                     >
    //                                         <i className="fa-solid fa-right-left fa-xl" onClick={()=>this.handleTransfer(msg["uuid"],msg["server"],msg["sip_user"],msg["dst"])} 
    //                                         id="transfer" data-tooltip-id="tooltip" data-tooltip-content={"Transfer call"}></i>
    //                                         <Tooltip id="tooltip" />
    //                                 </button> */}
    //                             </div>
    //                     </td>
    //                 </tr>
    //                 :null
    //                 :null
    //                 :
    //                 msg["dst"].substring(0,3)!=="810"?
    //                 !this.state.brandsRender.includes("all")?
    //                 this.state.brandsRender.includes(this.state.allowed[this.state.allowed.findIndex(val=>val.Login===msg["sip_user"])]?.Title)?
    //                 <tr key={msg['uuid']} style={{fontWeight:300,fontSize:"1.5vh",color:"black"}}>
    //                 {/* <td>{msg["server"]}</td> */}
    //                 <td>{msg["time"]}</td>
                    
    //                     <td>{msg["sip_user"]}</td>
    //                     {JSON.parse(secureLocalStorage.getItem('user')).role_id == 1 ?
    //                     <td>{this.state.allowed[this.state.allowed.findIndex(val=>val.Login===msg["sip_user"])]?.Title}</td>
    //                     :null
    //                     }
    //                     <td>{this.state.allowed[this.state.allowed.findIndex(val=>val.Login===msg["sip_user"])]?.team}</td>
    //                 <td>{msg["dst"].substring(0,2)}</td>
    //                 <td>{msg["dst"].substring(2)}</td>
    //                 {/* <td>
    //                 {
    //                     msg["status"]=='EARLY'
    //                         ?
    //                         <button className="btn btn-label-warning" disabled={true} style={{width:100}}>
    //                                 {t("onlineRinging")}
    //                         </button>
    //                         :<button className="btn btn-label-success" disabled={true} style={{width:100}}>
    //                         {t("onlineInCall")}
    //                         </button>
    //                 }
    //                 </td> */}
    //                 <td>{parseInt(msg["duration"])>3600?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(11, 19)
    //                                     :parseInt(msg["duration"]) < 60?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(17, 19) + ` ${t("onlineSeconds")}`
    //                                     : new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(14, 19)}</td>
    //                 <td>
    //                         <div>
    //                             <Link  to={`callto:99${msg["sip_user"]}`}>
    //                             <button className='btn' style={{
    //                                 border:2,
    //                                 borderRadius:4,
    //                                 width:"3vw",
    //                                 height:"3vh",
    //                                 margin:3,
    //                                 padding:5,
    //                             backgroundColor:'#25A0E2',
    //                             color:"#fff"
    //                                 }}
    //                                 >
    //                                     Listen
    //                                     <Tooltip id="tooltip" />
    //                             </button></Link>
    //                             <Link  to={`callto:98${msg["sip_user"]}`}>
    //                             <button className='btn' style={{
    //                                 border:2,
    //                                 borderRadius:4,
    //                                 width:"3vw",
    //                                 height:"3vh",
    //                                 margin:3,
    //                                 padding:5,
    //                             backgroundColor:"#00BD9D",
    //                             color:"#fff"
    //                                 }}
    //                                 >
    //                                     Sufler
    //                                     <Tooltip id="tooltip" />
    //                             </button>
    //                     </Link>
    //                             {/* 
    //                             <button className='btn' style={{
    //                                 border:2,
    //                                 borderRadius:4,
    //                                 width:"3vw",
    //                                 height:"3vh",
    //                                 margin:3,
    //                                 padding:5
    //                                 }}
    //                                 data-bs-toggle="modal" data-bs-target="#modalTop"
    //                                 >
    //                                     <i className="fa-solid fa-right-left fa-xl" onClick={()=>this.handleTransfer(msg["uuid"],msg["server"],msg["sip_user"],msg["dst"])}  
    //                                     id="transfer" data-tooltip-id="tooltip" data-tooltip-content={"Transfer call"}></i>
    //                                     <Tooltip id="tooltip" />
    //                             </button> */}
    //                         </div>
    //                 </td>
    //             </tr>
    //             :null
    //             :<tr key={msg['uuid']} style={{fontWeight:300,fontSize:"1.5vh",color:"black"}}>
    //             {/* <td>{msg["server"]}</td> */}
    //             <td>{msg["time"]}</td>
                
    //                     <td>{msg["sip_user"]}</td>
    //                     {JSON.parse(secureLocalStorage.getItem('user')).role_id == 1 ?
    //                     <td>{this.state.allowed[this.state.allowed.findIndex(val=>val.Login===msg["sip_user"])]?.Title}</td>
    //                     :null
    //                     }
    //                     <td>{this.state.allowed[this.state.allowed.findIndex(val=>val.Login===msg["sip_user"])]?.team}</td>
    //             <td>{msg["dst"].substring(0,2)}</td>
    //             <td>{msg["dst"].substring(2)}</td>
    //             {/* <td>
    //             {
    //                 msg["status"]=='EARLY'
    //                     ?
    //                     <button className="btn btn-label-warning" disabled={true} style={{width:100}}>
    //                             {t("onlineRinging")}
    //                     </button>
    //                     :<button className="btn btn-label-success" disabled={true} style={{width:100}}>
    //                     {t("onlineInCall")}
    //                     </button>
    //             }
    //             </td> */}
    //             <td>{parseInt(msg["duration"])>3600?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(11, 19)
    //                                 :parseInt(msg["duration"]) < 60?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(17, 19) + ` ${t("onlineSeconds")}`
    //                                 : new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(14, 19)}</td>
    //             <td>
    //                     <div>
    //                         <Link  to={`callto:99${msg["sip_user"]}`}>
    //                         <button className='btn' style={{
    //                             border:2,
    //                             borderRadius:4,
    //                             width:"3vw",
    //                             height:"3vh",
    //                             margin:3,
    //                             padding:5,
    //                             backgroundColor:'#25A0E2',
    //                             color:"#fff"
    //                             }}
    //                             >
    //                                 Listen
    //                                 <Tooltip id="tooltip" />
    //                         </button></Link>
    //                         <Link  to={`callto:98${msg["sip_user"]}`}>
    //                         <button className='btn' style={{
    //                             border:2,
    //                             borderRadius:4,
    //                             width:"3vw",
    //                             height:"3vh",
    //                             margin:3,
    //                             padding:5,
    //                             backgroundColor:"#00BD9D",
    //                             color:"#fff"
    //                             }}
    //                             >
    //                                 Sufler
    //                                 <Tooltip id="tooltip" />
    //                         </button>
    //                 </Link>
                
    //                         {/* 
    //                         <button className='btn' style={{
    //                             border:2,
    //                             borderRadius:4,
    //                             width:"3vw",
    //                             height:"3vh",
    //                             margin:3,
    //                             padding:5
    //                             }}
    //                             data-bs-toggle="modal" data-bs-target="#modalTop"
    //                             >
    //                                 <i className="fa-solid fa-right-left fa-xl" onClick={()=>this.handleTransfer(msg["uuid"],msg["server"],msg["sip_user"],msg["dst"])}  
    //                                 id="transfer" data-tooltip-id="tooltip" data-tooltip-content={"Transfer call"}></i>
    //                                 <Tooltip id="tooltip" />
    //                         </button> */}
    //                     </div>
    //             </td>
    //             </tr>
    //             :null
    //             :null
    //             ))
    //         } 
    //         catch (e) {
    //             console.log(e.message);
    //         }
    //         };
    showTable1 = () => {
        const { t } = this.props;
            try {
                return JSON.parse(this.state.names1).sort((a,b)=>a["duration"]>b["duration"]?-1:1).map((msg,idx)=>(
                    msg["dst"].match(/^(\d{14,})$/)!=null ?
                    JSON.parse(secureLocalStorage.getItem('user')).role_id !== 1 ?
                    this.state.allowed.some(us=>us.Login===msg["sip_user"])?
                    msg["dst"].substring(0,3)!=="810"?
                    <tr key={msg['uuid']} style={{fontWeight:300,fontSize:"1.5vh",color:"black"}}>
                        {/* <td>{msg["server"]}</td> */}
                        <td>{msg["time"]}</td>
                        
                        <td>{msg["sip_user"]}</td>
                        {JSON.parse(secureLocalStorage.getItem('user')).role_id == 1 ?
                        <td>{this.state.allowed[this.state.allowed.findIndex(val=>val.Login===msg["sip_user"])].Title}</td>
                        :null
                        }
                        <td>{this.state.allowed[this.state.allowed.findIndex(val=>val.Login===msg["sip_user"])].team}</td>
                        <td>{msg["dst"].substring(0,2)}</td>
                        <td>{msg["dst"].substring(2)}</td>
                        {/* <td>{msg["status"]=='EARLY'
                            ?
                            <button className="btn btn-label-warning" disabled={true} style={{width:100}}>
                                    {t("onlineRinging")}
                            </button>
                            :<button className="btn btn-label-success" disabled={true} style={{width:100}}>
                            {t("onlineInCall")}
                        </button>}
                        </td> */}
                        <td>{parseInt(msg["duration"])>3600?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(11, 19)
                                        :parseInt(msg["duration"]) < 60?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(17, 19) + ` ${t("onlineSeconds")}`
                                        : new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(14, 19)}</td>
                        <td>
                            <div style={{width:"100%"}}>
                                <Link  to={`callto:99${msg["sip_user"]}`}>
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:4,
                                        width:"8vw",
                                        height:"3vh",
fontSize:"1.4vh",
                                        margin:"0.3vh",
                                        padding:"0.5vh",
backgroundColor:'#25A0E2',
color:"#fff"
                                        }}
                                        >
                                            Listen
                                            <Tooltip id="tooltip" />
                                    </button>
                                </Link>
                                <Link  to={`callto:98${msg["sip_user"]}`}>
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:4,
                                        width:"8vw",
                                        height:"3vh",
fontSize:"1.4vh",
                                        margin:"0.3vh",
                                        padding:"0.5vh",
backgroundColor:"#00BD9D",
color:"#fff"
                                        }}
                                        >
                                            Sufler
                                            <Tooltip id="tooltip" />
                                    </button>
                                </Link>
                                {/* 
                                <button className='btn' style={{
                                    border:2,
                                    borderRadius:4,
                                    width:"3vw",
                                    height:"3vh",
                                    margin:3,
                                    padding:5
                                    }}
                                    data-bs-toggle="modal" data-bs-target="#modalTop"
                                    >
                                        <i className="fa-solid fa-right-left fa-xl" onClick={()=>this.handleTransfer(msg["uuid"],msg["server"],msg["sip_user"],msg["dst"])}  
                                        id="transfer" data-tooltip-id="tooltip" data-tooltip-content={"Transfer call"}></i>
                                        <Tooltip id="tooltip" />
                                </button> */}
                            </div>
                        </td>
                    </tr>
                    :null
                    :null
                    :
                    msg["dst"].substring(0,3)!=="810"?
                    this.state.brandsRender.includes(this.state.allowed[this.state.allowed.findIndex(val=>val.Login===msg["sip_user"])].Title)?
                    <tr key={msg['uuid']} style={{fontWeight:300,fontSize:"1.5vh",color:"black"}}>
                    {/* <td>{msg["server"]}</td> */}
                    <td>{msg["time"]}</td>
                    
                        <td>{msg["sip_user"]}</td>
                        {JSON.parse(secureLocalStorage.getItem('user')).role_id == 1 ?
                        <td>{this.state.allowed[this.state.allowed.findIndex(val=>val.Login===msg["sip_user"])].Title}</td>
                        :null
                        }
                        <td>{this.state.allowed[this.state.allowed.findIndex(val=>val.Login===msg["sip_user"])].team}</td>
                    <td>{msg["dst"].substring(0,2)}</td>
                    <td>{msg["dst"].substring(2)}</td>
                    {/* <td>
                    {msg["status"]=='EARLY'
                            ?
                            <button className="btn btn-label-warning" disabled={true} style={{width:100}}>
                                    {t("onlineRinging")}
                            </button>
                            :<button className="btn btn-label-success" disabled={true} style={{width:100}}>
                                    {t("onlineInCall")}
                            </button>
                        }
                    </td> */}
                    <td>{parseInt(msg["duration"])>3600?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(11, 19)
                                        :parseInt(msg["duration"]) < 60?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(17, 19) + ` ${t("onlineSeconds")}`
                                        : new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(14, 19)}</td>
                    <td>
                            <div>
                                <Link  to={`callto:99${msg["sip_user"]}`}>
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:4,
                                        width:"8vw",
                                        height:"3vh",
fontSize:"1.4vh",
                                        margin:"0.3vh",
                                        padding:"0.5vh",
backgroundColor:'#25A0E2',
color:"#fff"
                                        }}
                                        >
                                            Listen
                                            <Tooltip id="tooltip" />
                                    </button>
                                </Link>
                                <Link  to={`callto:98${msg["sip_user"]}`}>
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:4,
                                        width:"8vw",
                                        height:"3vh",
fontSize:"1.4vh",
                                        margin:"0.3vh",
                                        padding:"0.5vh",
backgroundColor:"#00BD9D",
color:"#fff"
                                        }}
                                        >
                                            Sufler
                                            <Tooltip id="tooltip" />
                                    </button>
                                </Link>
                                {/* 
                                <button className='btn' style={{
                                    border:2,
                                    borderRadius:4,
                                    width:"3vw",
                                    height:"3vh",
                                    margin:3,
                                    padding:5
                                    }}
                                    data-bs-toggle="modal" data-bs-target="#modalTop"
                                    >
                                        <i className="fa-solid fa-right-left fa-xl" onClick={()=>this.handleTransfer(msg["uuid"],msg["server"],msg["sip_user"],msg["dst"])}  
                                        id="transfer" data-tooltip-id="tooltip" data-tooltip-content={"Transfer call"}></i>
                                        <Tooltip id="tooltip" />
                                </button> */}
                            </div>
                    </td>
                </tr>
                :null
                :null
                :null
                ))
            } 
            catch (e) {
                console.log(e.message);
            }
            };
    render(){
        const { t } = this.props;
        return(
            <div className="table-responsive p-0 pb-2"  style={{minHeight:"50vh",maxHeight:"70vh",overflowY:"auto", fontFamily:"Poppins"}}>
                {/* <div>
                    <p>Agents in call: {Number(JSON.parse(this.state.names1).length)+Number(JSON.parse(this.state.names).length)}</p>
                </div> */}
                <div className="modal fade" data-bs-backdrop="static" id="modalTop" tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered">
                        <form className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTopTitle">Transfer call</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>this.handleClose()}/>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col mb-3">
                                    <label htmlFor="nameSlideTop" className="form-label">From user</label>
                                    <input type="text" id="nameSlideTop" className="form-control" disabled={true} value={this.state.name} />
                                </div>
                            </div>
                            <div className="row g-2">
                                <div className="col mb-0">
                                    <label htmlFor="emailSlideTop" className="form-label">Client number</label>
                                    <input type="text" id="emailSlideTop" className="form-control"disabled={true} value={this.state.dst}  />
                                </div>
                                <div className="col mb-0">
                                    <label htmlFor="dobSlideTop" className="form-label">Prefix</label>
                                    <input type="text" id="dobSlideTop" className="form-control" disabled={true} value={this.state.prefix}  />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col mb-3">
                                <Select options={this.state.options} 
                                    onChange={(e)=>this.handleChoose(e)}
                                    placeholder='Select agent' id='prefixupdLb'
                                    styles={{
                                        control:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#b8b8cd",
                                            borderColor:"#444663"
                                        }),
                                        option: (styles, { isFocused, isSelected }) => {
                                            return {
                                                ...styles,
                                                backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                            };
                                        },
                                        singleValue:(baseStyles,state)=>({
                                            ...baseStyles,
                                            color:"#b8b8cd"
                                        }),
                                        placeholder:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#b8b8cd"
                                        }),
                                        menuList:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#b8b8cd",
                                        }),
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" onClick={()=>this.handleClose()}>Close</button>
                            <button type="button" className="btn btn-primary" disabled={this.state.transferto!==""?false:true}>Transfer</button>
                        </div>
                        </form>
                    </div>
                </div>
                {
                    JSON.parse(secureLocalStorage.getItem('user')).role_id !== 1 
                    ?
                    null
                    :
                    <div className="nav-align-top">
                                <ul className="nav nav-tabs">
                                {
                                this.state.brands.map((val,ind)=>{
                                    return <li className="nav-item" key={ind}>
                                    <button type="button" id={val} className="btn"
                                    role="tab" style={{margin:"0.5vh", fontSize:"1.5vh"}} 
                                    data-bs-toggle="tab" data-bs-target="#navs-top-home" 
                                    aria-controls="navs-top-home" aria-selected="true"
                                    onClick={(event)=>{
                                        if(!this.state.brandsRender.includes(val)){
                                            this.state.brandsRender.push(val);
                                            event.target.classList.toggle('btn-primary');
                                        }
                                        else {
                                            event.target.classList.toggle('btn-primary');
                                            this.state.brandsRender.splice(this.state.brandsRender.indexOf(val),1);
                                        }
                                    console.log(this.state.brandsRender);
                                    console.log(event.target.classList);
                                    }}>
                                        {val}</button> 
                                    </li>
                                })
                            }
                                </ul>
                    </div>
                }
                <table id="tablecalls" className="table table-stripped align-items-center justify-content-center mb-0 cell-border hover compact row-border order-column stripe" >
                    <thead style={{color:'black'}}>
                    <tr >
                            <th className="text-uppercase text-secondary text-sm opacity-8 ps-2 table-rounded-header" style={{fontSize:"1.8vh"}}>{t("onlineThTime")}</th>
                            
                            <th className="text-uppercase text-secondary text-sm opacity-8 ps-2 table-rounded-header" style={{fontSize:"1.8vh"}}>Team</th>
                            <th className="text-uppercase text-secondary text-sm opacity-8 ps-2 table-rounded-header" style={{fontSize:"1.8vh"}}>Agent</th>
                            {JSON.parse(secureLocalStorage.getItem('user')).role_id == 1 ?
                            <th className="text-uppercase text-secondary text-sm opacity-8 ps-2 table-rounded-header" style={{fontSize:"1.8vh"}}>Brand</th>
                            :null
                            }
                            <th className="text-uppercase text-secondary text-sm opacity-8 ps-2 table-rounded-header" style={{fontSize:"1.8vh"}}>From</th>
                            <th className="text-uppercase text-secondary text-sm opacity-8 ps-2 table-rounded-header" style={{fontSize:"1.8vh"}}>To</th>
                            <th className="text-uppercase text-secondary text-sm opacity-8 ps-2 table-rounded-header" style={{fontSize:"1.8vh"}}>Length</th>
                            <th className="text-uppercase text-secondary text-sm opacity-8 ps-2 table-rounded-header" style={{fontSize:"1.8vh"}}>{t("onlineThActions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                            {
                                this.state.names.length>0?this.showTable():null
                            }
                    </tbody>
                </table>
            </div>
    )
    }
}


export default withTranslation()(OnlineTable);
